import type { NamedFC } from 'shared-ui/named-fc.types';
import GenericError from 'shared-ui/pages/generic-error/generic-error.page';

import ChooseClub from '../functions/choose-club/choose-club.page';
import ChooseMemberType from '../functions/choose-member-type/choose-member-type.page';
import ChooseMembership from '../functions/choose-membership/choose-membership.page';
import Confirmation from '../functions/confirmation/confirmation.page';
import CustomizeMembership from '../functions/customize-membership/customize-membership.page';
import FreeMembershipLanding from '../functions/free-membership-landing/free-membership-landing.page';
import FreeMembershipRegister from '../functions/free-membership-register/free-membership-register.page';
import Login from '../functions/login/login.page';
import Payment from '../functions/payment/payment.page';
import PaymentStatus from '../functions/payment-status/payment-status.page';
import PocChooseMembership from '../functions/poc-choose-membership/poc-choose-membership.page';
import PocPayment from '../functions/poc-payment/poc-payment.page';
import PocRegister from '../functions/poc-register/poc-register.page';
import Register from '../functions/register/register.page';

/** This export is automatically generated. */
export const pages: Record<string, NamedFC> = {
  ChooseClub,
  ChooseMemberType,
  ChooseMembership,
  Confirmation,
  CustomizeMembership,
  FreeMembershipLanding,
  FreeMembershipRegister,
  GenericError,
  Login,
  Payment,
  PaymentStatus,
  PocChooseMembership,
  PocPayment,
  PocRegister,
  Register,
};

import React from 'react';

import LinkButton from 'sats-ui-lib/react/link-button/link-button';
import SvgElixia from 'sats-ui-lib/react/logos/elixia';
import Sats from 'sats-ui-lib/react/logos/sats';
import Text from 'sats-ui-lib/react/text/text';

import type { NamedFC } from 'shared-ui/named-fc.types';

import { GenericError as Props } from './generic-error.props';

const GenericError: NamedFC<Props> = ({ language, link, text }) => {
  return (
    <div className="generic-error">
      <div className="generic-error__logo">
        {language === 'fi' ? <SvgElixia /> : <Sats />}
      </div>
      <Text elementName="h1" size={Text.sizes.large}>
        {text}
      </Text>
      {link ? (
        <LinkButton
          data-test-go-back
          variant={LinkButton.variants.secondaryWhite}
          {...link}
        />
      ) : null}
    </div>
  );
};

GenericError.displayName = 'GenericError';

export default GenericError;
